var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-cols-8 gap-6 pt-3"},[_c('div',{staticClass:"col-span-8 md:col-span-4"},[_c('h1',[_vm._v("Sign up")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"grid grid-cols-2 gap-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signUp)}}},[_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"mode":"lazy","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.email),expression:"details.email"}],staticClass:"\n              w-full\n              px-3\n              py-2\n              border border-black border-box\n              leading-none\n              outline-none\n            ",attrs:{"type":"email","name":"Email"},domProps:{"value":(_vm.details.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "email", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-1",attrs:{"mode":"lazy","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.firstName),expression:"details.firstName"}],staticClass:"\n              w-full\n              px-3\n              py-2\n              border border-black border-box\n              leading-none\n              outline-none\n            ",attrs:{"type":"text","name":"Name"},domProps:{"value":(_vm.details.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "firstName", $event.target.value)}}}),(errors.length > 0)?_c('span',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-1",attrs:{"mode":"lazy","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("Surname")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.familyName),expression:"details.familyName"}],staticClass:"\n              w-full\n              px-3\n              py-2\n              border border-black border-box\n              leading-none\n              outline-none\n            ",attrs:{"type":"text","name":"Surname"},domProps:{"value":(_vm.details.familyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "familyName", $event.target.value)}}}),(errors.length > 0)?_c('span',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-1",attrs:{"mode":"lazy","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("Country")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.country),expression:"details.country"}],staticClass:"\n              w-full\n              px-3\n              py-2\n              border border-black border-box\n              leading-none\n              outline-none\n            ",attrs:{"name":"Country"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.details, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","hidden":"","selected":"","value":""}},[_vm._v("Select")]),_vm._l((_vm.countries),function(country,i){return _c('option',{key:i,domProps:{"value":country}},[_vm._v(" "+_vm._s(country)+" ")])})],2),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"mode":"lazy","rules":{
            length: 4,
            regex: /sand/i,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('span',[_c('b',[_vm._v("Verification question:")]),_vm._v(" "),_c('br'),_vm._v("On what ground does a strandbeest prefer to walk? (4 letter word)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.answer),expression:"answer"}],staticClass:"\n              w-full\n              px-3\n              py-2\n              border border-black border-box\n              leading-none\n              outline-none\n            ",attrs:{"type":"answer","name":"Answer"},domProps:{"value":(_vm.answer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.answer=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"col-span-2"},[_c('button',{staticClass:"bg-black text-white rounded-full outline-none px-2",attrs:{"type":"submit"}},[_vm._v(" Sign up ")])])],1)]}}])}),(_vm.response)?_c('div',{staticClass:"pt-3"},[_c('span',[_vm._v(_vm._s(_vm.response.message))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }