<template>
  <div class="grid grid-cols-8 gap-6 pt-3">
    <div class="col-span-8 md:col-span-4">
      <h1>Sign up</h1>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form
          class="grid grid-cols-2 gap-6"
          @submit.prevent="handleSubmit(signUp)"
        >
          <ValidationProvider
            mode="lazy"
            class="col-span-2"
            rules="required|email"
            tag="div"
            v-slot="{ errors }"
          >
            <span>E-mail</span>
            <input
              class="
                w-full
                px-3
                py-2
                border border-black border-box
                leading-none
                outline-none
              "
              v-model="details.email"
              type="email"
              name="Email"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            mode="lazy"
            rules="required"
            v-slot="{ errors }"
            tag="div"
            class="col-span-1"
          >
            <span>Name</span>
            <input
              class="
                w-full
                px-3
                py-2
                border border-black border-box
                leading-none
                outline-none
              "
              type="text"
              name="Name"
              v-model="details.firstName"
            />
            <span v-if="errors.length > 0">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            mode="lazy"
            rules="required"
            v-slot="{ errors }"
            tag="div"
            class="col-span-1"
          >
            <span>Surname</span>
            <input
              class="
                w-full
                px-3
                py-2
                border border-black border-box
                leading-none
                outline-none
              "
              type="text"
              name="Surname"
              v-model="details.familyName"
            />
            <span v-if="errors.length > 0">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            mode="lazy"
            class="col-span-1"
            tag="div"
            rules="required"
            v-slot="{ errors }"
          >
            <span>Country</span>
            <select
              class="
                w-full
                px-3
                py-2
                border border-black border-box
                leading-none
                outline-none
              "
              name="Country"
              v-model="details.country"
            >
              <option disabled hidden selected value="">Select</option>
              <option
                v-for="(country, i) in countries"
                :key="i"
                :value="country"
              >
                {{ country }}
              </option>
            </select>
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            mode="lazy"
            class="col-span-2"
            :rules="{
              length: 4,
              regex: /sand/i,
            }"
            v-slot="{ errors }"
          >
            <span
              ><b>Verification question:</b> <br />On what ground does a
              strandbeest prefer to walk? (4 letter word)</span
            >
            <input
              class="
                w-full
                px-3
                py-2
                border border-black border-box
                leading-none
                outline-none
              "
              v-model="answer"
              type="answer"
              name="Answer"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="col-span-2">
            <button
              class="bg-black text-white rounded-full outline-none px-2"
              type="submit"
            >
              Sign up
            </button>
          </div>
        </form>
      </ValidationObserver>
      <div class="pt-3" v-if="response">
        <span>{{ response.message }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { extend } from "vee-validate";
import { email, required, length, regex } from "vee-validate/dist/rules";

import { countries } from "./countries";

extend("email", email);
extend("length", length);
extend("regex", regex);
extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  name: "SignUp",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      countries,
      api: process.env.VUE_APP_API_URL,
      details: {
        firstName: "",
        familyName: "",
        email: "",
        country: "",
      },
      answer: "",
      response: null,
    };
  },
  methods: {
    signUp() {
      const details = {
        firstName: this.details.firstName,
        familyName: this.details.familyName,
        country: this.details.country,
        email: this.details.email,
      };

      axios.post(`${this.api}/sign-up`, details).then((response) => {
        this.response = response.data;
      });
    },
  },
};
</script>

<style>
</style>